.CardNoticias {
  &__container {
    width: 100%;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
  }
  &__info {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding-bottom: 0 !important;
  }
  &__description,
  &__date,
  &__leerMas {
    font-size: 0.9em;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.7);
  }
  &__date {
    display: flex;
    align-items: center;
    color: rgb(230, 230, 230);
    svg {
      margin-right: 3px;
    }
  }
  &__description {
    font-weight: bold;
    margin: 0;
    color: white;
  }
  &__leerMas {
    color: white;
    width: max-content;
    text-decoration: none;
    background-color: var(--cuarto);
    padding: 2px 5px 2px 5px;
    border-radius: 3px;
    font-weight: bold;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  }
  &__leerMas:hover {
    color: white;
  }
}

// @media (max-width: 992px) {
//   .NDimagenes {
//     &__Col3 {
//       display: flex;
//       justify-content: center;
//       margin-top: 20px !important;
//       width: 100vw !important;
//     }
//   }
// }

// @media (max-width: 768px) {
//   .NDimagenes {
//     &__Col12 {
//       display: flex;
//       justify-content: center;
//       margin-top: 20px !important;
//       width: 100vw !important;
//     }
//   }
// }
