.NecesidadDeCapacitar {
  &__Cards {
    display: flex;
    justify-content: center;
    text-align: center;
    img {
      border-bottom: 4px solid var(--primario);
      filter: sepia(100%) hue-rotate(165deg) saturate(200%) brightness(90%);
    }
  }
  &__Cards:first-child {
    img {
      border-bottom-left-radius: 50%;
    }
  }
  &__Cards:last-child {
    img {
      border-bottom-right-radius: 50%;
    }
  }
  &__CardBody {
    position: relative;
  }
  &__icon {
    color: black;
    position: absolute;
    top: -20px;
    right: 40%;
    background-color: var(--primario);
    border-radius: 50%;
    padding: 5px;
  }
  &__title {
    padding-top: 10px;
  }
}

@media (max-width: 600px) {
}
