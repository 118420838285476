.footer {
  &__container {
    background-color: var(--secundario);
    color: white;
    width: 100%;
    text-align: center;
    padding: 1%;
  }
  &__coldown {
    align-items: center;
    i {
      font-size: 2em;
      padding: 2%;
      cursor: pointer;
      color: white;
    }
  }
  &__colup {
    padding: 1%;
    a {
      text-decoration: none;
    }
  }
  &__logomp img {
    width: 150px;
    filter: grayscale(100%) invert(100%);
    -webkit-filter: grayscale(100%) invert(100%);
    -moz-filter: grayscale(100%) invert(100%);
  }
}
