.cards {
  &__imagen {
    filter: blur(1px) opacity(78%);
    -webkit-filter: blur(1px) opacity(78%);
    -moz-filter: blur(1px) opacity(78%);
    opacity: 0.3;
    height: 500px;
  }
  &__titulo {
    color: var(--terciario);
  }
}

.dataCards {
  &__container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    justify-content: center;
    align-items: center;
    justify-items: center;
  }
}

@media (max-width: 600px) {
  .cards {
    &__div {
      width: 100vw;
      padding: 0;
      margin: 0;
      align-self: center;
    }
    &__btn {
      position: relative;
      opacity: 1;
      backdrop-filter: none;
    }
  }
  .dataCards {
    &__container {
      margin: 50px;
      grid-template-columns: repeat(auto-fit, minmax(100vw, 1fr));
    }
  }
  // .dataCards {
  //   grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  // }
}
