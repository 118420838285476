.top-right-modal {
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 300px;
  background-color: #f8f9fa;
  border: 1px solid #ccc;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: 9999; // Asegura que el modal esté por encima de otros elementos

  .top-right-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h5 {
      margin: 0;
      color: #d9534f; // Rojo para resaltar la urgencia
      font-size: 16px;
    }

    .close-btn {
      background: none;
      border: none;
      font-size: 18px;
      cursor: pointer;
    }
  }

  .modal-body {
    h6 {
      font-size: 18px;
      font-weight: bold;
      margin-top: 10px;
      color: var(--primario); // Azul para asociar con confianza y seguridad
    }

    p {
      font-size: 14px;
      color: #555;
      margin: 5px 0;
    }

    strong {
      font-weight: bold;
      color: #28a745; // Verde para dar sensación de éxito y esperanza
    }

    .contact-info {
      margin-top: 10px;

      a {
        color: var(--primario);
        font-weight: bold;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
