.leyes {
  &__container {
    margin-top: 8%;
    border-top: solid 1px black;
    padding-top: 10%;
    margin-bottom: 5%;
    border-bottom: solid 1px black;
    padding-bottom: 5%;
  }
}

.leyesb {
  margin-top: 5%;
  margin-bottom: 5%;
}

.leyesb::before {
  content: "⌟";
  font-weight: bold;
  color: var(--cuarto);
}
