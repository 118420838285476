.VerVideo {
  &__container-video {
    display: flex;
    justify-content: center;
  }
  &__container {
    display: flex;
    justify-content: center;
    background: rgb(89, 154, 212);
    background: linear-gradient(
      86deg,
      rgba(89, 154, 212, 1) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    margin-top: 100px;
  }
  &__text {
    display: flex;
    justify-content: center;
    flex-direction: column;
    button {
      width: max-content;
    }
    p {
      width: 500px;
    }
  }
}

@media (max-width: 700px) {
  .VerVideo {
    &__text {
      p {
        width: 100%;
      }
    }
    &__containerImg {
      img {
        width: 200px;
      }
    }
  }
}
