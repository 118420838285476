.contacto {
  &__container {
    margin-top: 2.5%;
    margin-bottom: 10%;
    border-top: solid 1px black;
    padding-top: 2.5%;
  }
  &__form {
    margin-right: 10%;
  }
  &__titulo {
    text-align: center;
    margin-top: 2%;
  }
  &__trenque {
    margin-bottom: 5%;
  }
  &__btn {
    margin-top: 5%;
    margin-bottom: 5%;
  }
  &__b {
    b {
      color: var(--secundario2);
    }
  }
  &__img {
    img {
      width: 240px;
      height: auto;
    }
  }
}
