.Navbar3 {
  &__nav {
    font-weight: bold;
    translate: 25vw;
  }

  &__losli {
    margin-right: 10px;
    position: relative;
  }
  &__losli:after {
    content: "";
    position: absolute;
    width: 0%;
    height: 1px;
    display: block;
    transition: all 0.3s ease;
    bottom: 8%;
  }
  &__losli:hover::after {
    width: 100%;
    background-color: var(--cuarto);
  }
  &__contenedor-tel {
    display: flex;
    p {
      margin: 0;
      padding: 0;
    }
    b {
      color: var(--secundario2);
    }
  }
  &__icon {
    margin-bottom: 7px;
    font-size: 1.2em;
  }
  &__telefonos {
    margin-left: 10px;
    span {
      color: var(--primario);
      font-weight: bold;
    }
  }
}
.nav-cont {
  position: sticky;
  top: 0;
  z-index: 10;
}
@media (max-width: 600px) {
  .img-logotipo {
    width: 250px;
    height: auto;
  }
  .Navbar3__losli:hover::after {
    width: 0%;
  }
}
