* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

// body {
//   margin: 0 10% 0 10%;
// }
:root {
  --primario: #599AD4;
  --secundario: #0d0d0d;
  --secundario2: #808080;
  --terciario: #FECA0A;
  --cuarto: #EE1D23;
  --borderBottom: 1px solid rgba(148, 148, 148, 0.274);
}
// $color-primario: #64d0c3;
//   $color-secundario: #6b6;
//   $color-texto: #666;
$primary: #599AD4;
$secondary: #EE1D23;
$warning: #FECA0A;
@import "bootstrap";
