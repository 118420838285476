.dashboard_container-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.37);
  border-radius: 2px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  /* padding: 0 40px 20px 40px; */
  margin: 5%;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  background: rgba(255, 255, 255, 0.6);
  width: 200px;
  height: 150px;
  cursor: pointer;
  background-color: var(--primario);
}

.dashboard-tituloPrincipal {
  margin-left: 1.7%;
  margin-right: 1.7%;
  margin-top: 5%;
  display: flex;
  align-items: center;
  border-bottom: var(--borderBottom);
}

.dashboard-tituloPrincipal svg {
  margin-right: 7px;
  margin-bottom: 4px;
}

.dashboard_title {
  color: white;
  width: 200px;
  text-align: center;
  margin-top: 17px;
}

.dashboard_container h5 {
  padding: 10px;
  font-size: 1em;
  border-bottom: var(--borderBottom);
  color: var(--tertiary);
}

.dashboard_grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  justify-items: center;
}

.dashboard_container {
  margin: 20px;
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 4px 10px 0 rgba(95, 95, 95, 0.37);
  backdrop-filter: blur(6.5px);
  -webkit-backdrop-filter: blur(6.5px);
  border-radius: 5px;
  border-top: 3px solid var(--primario);
}

.dashboard_icon {
  font-size: 4em;
}

.dashboard_allContainer {
  height: 62vh;
}
