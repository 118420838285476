.home {
  &__containerSlider {
    display: flex;
    justify-content: center;
  }
  &__titular {
    padding: 3%;
    font-size: 1.7em;
  }

  &__intro {
    width: 50vw;
    height: 70vh;
    display: flex;
    padding-left: 5%;
    align-items: center;
    box-shadow: 0 10px 20px rgba(211, 233, 245, 0.2);
    background: linear-gradient(86deg, #5f9dd3 0%, rgba(255, 255, 255, 1) 100%);
    // filter: brightness(60%);
    &--content {
      display: flex;
      flex-direction: column;
      span {
        color: var(--cuarto);
      }
    }
  }

  &__sobremi::before {
    content: "⌟";
    font-weight: bold;
    color: var(--cuarto);
  }
  &__containerIntroduction {
    margin-top: 5%;
  }

  &__sobremi {
    font-size: 1.5em;
  }

  &__instrucciones {
    border-left: 1px solid var(--cuarto);
  }

  &__inscripciones {
    background-color: var(--cuarto);
    text-align: center;
    padding-top: 1%;
    margin-top: 5%;
    margin-bottom: 5%;
    cursor: pointer;
    transition: 0.5s;
    a {
      color: white;
      text-decoration: none;
    }
    a:hover {
      color: white;
      text-decoration: none;
    }
  }
  &__inscripciones:hover {
    background-color: var(--primario);
    transition: 0.5s;
  }
}

@media (max-width: 575px) {
  .home {
    &__instrucciones {
      padding-top: 10%;
      padding-bottom: 5%;
      border-left: none;
    }
    &__containerSlider {
      flex-direction: column;
    }
  }
}

@media (max-width: 700px) {
  .home {
    &__intro {
      display: none;
    }
  }
}
