.confian {
  &__container {
    margin-bottom: 20px;
    h2 {
      text-align: center;
    }
  }
  &__slider {
    width: 75vw;
    height: auto;
    margin: auto;
    overflow: hidden;
  }
  &__slide-track {
    display: flex;
    align-items: center;
    animation: scroll 40s linear infinite;
    -webkit-animation: scroll 40s linear infinite;
    width: calc(200px * 12);
  }
  &__slide {
    width: 200px;
    img {
      width: 100%;
    }
  }
}

@keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(calc(-200px * 6));
    transform: translateX(calc(-200px * 6));
  }
}

@media (max-width: 500px) {
  .confian__colImg {
    width: 200px;
  }
}
