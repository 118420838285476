.slick-arrow {
  display: none !important;
}

.NDcapacitaciones {
  &__noticias {
    margin: 0 0 10px 24px;
  }
  &__noticias::before {
    content: "⌟";
    font-weight: bold;
    color: var(--cuarto);
  }
  &__slider {
    margin-top: 50px !important;
    margin-bottom: 50px;
    border: 2px solid var(--terciario) !important;
    background-color: var(--terciario);
  }
  &__banner {
    position: relative;
    z-index: 1;
    padding-left: 10%;
    padding-top: 5%;
    padding-bottom: 5%;
  }
  &__banner::before {
    background: url(imgDeCard.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    z-index: -1;
  }
}
