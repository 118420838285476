.fechas {
  &__container {
    h3 {
      text-align: center;
      padding: 3%;
    }
  }
  &__inscribirse {
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 10px;
  }
  &__titlepago {
    background-color: rgb(230, 230, 230);
    border-bottom: 1px solid black;
    cursor: pointer;
    margin: 0px !important;
    padding: 0px !important;
    img {
      width: 300px;
      height: auto;
    }
  }
  &__aunquedan p {
    font-weight: bold;
    color: green;
  }
  &__info {
    display: flex;
    justify-content: space-around;
    margin: 5%;
    h3 {
      border: 3px solid #da2f2f;
    }
    h3::after {
      content: " >";
      font-weight: bold;
      color: var(--primario);
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
    }
    img {
      width: 300px;
      height: auto;
    }
  }
  &__width {
    width: 600px;
  }
  &__containerimg {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 600px) {
  .fechas {
    &__info {
      flex-direction: column;

      margin: 0;
    }
    &__width {
      width: 100vw;
    }
  }
}

.infofecha {
  &__container {
    h4::before {
      content: "⌟";
      font-weight: bold;
      color: var(--cuarto);
    }
    margin-bottom: 5%;
    position: relative;
    z-index: 1;
    box-shadow: 0 8px 32px 0 rgba(100, 208, 195, 0.37);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    padding: 5%;
    span {
      font-weight: bold;
    }
    b {
      color: var(--primario);
    }
  }
  &__container::before {
    background: url(imgCurso1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    z-index: -1;
  }
  &__button {
    margin-top: 3%;
  }
  &__cursoConcluido {
    color: var(--cuarto);
  }
}

@media (max-width: 600px) {
  .infofecha__container {
    width: 100vw;
  }
}
