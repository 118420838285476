.container-slider {
  width: 50vw;
  height: 70vh;
  position: relative;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(190, 215, 228, 0.2);
  filter: sepia(100%) hue-rotate(165deg) saturate(200%) brightness(90%);
}

.container-slider::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 20%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(224, 247, 250, 0) 0%,
    var(--primario) 100%
  );
  pointer-events: none;
}

.slide {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  transition: opacity ease-in-out 0.4s;
}
.slide img {
  width: 100%;
  height: 100%;
}
.active-anim {
  opacity: 1;
}

.btn-slide {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #f1f1f1;
  border: 1px solid rgba(34, 34, 34, 0.287);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btn-slide img {
  width: 25px;
  height: 25px;
  pointer-events: none;
}
.prev {
  top: 57%;
  left: 20px;
  transform: translateY(-60%);
}
.next {
  top: 57%;
  right: 20px;
  transform: translateY(-60%);
}

.container-dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}
.dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid #f1f1f1;
  margin: 0 5px;
  background: #f1f1f1;
}
.dot.active {
  background: rgb(32, 32, 32);
}

@media screen and (max-width: 990px) {
  .container-slider {
    height: 70vh;
  }
}

@media screen and (max-width: 700px) {
  .container-slider {
    height: 40vh;
    width: 100%;
  }

  .btn-slide {
    width: 40px;
    height: 40px;
    img {
      width: 18px;
      height: 18px;
    }
  }
}
