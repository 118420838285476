.BannerHeader {
  &__container {
    text-align: center;
    color: white;
    border: none !important;
  }
  &__container img {
    height: 250px;
    object-fit: cover;
    filter: blur(4px) brightness(78%) opacity(95%);
    -webkit-filter: blur(4px) brightness(78%) opacity(95%);
    -moz-filter: blur(4px) brightness(78%) opacity(95%);
  }
  &__container p {
    font-size: 1.2em;
    color: var(--fourth);
  }
  &__textBanner {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
