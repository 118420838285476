.programas {
  &__divp {
    margin-top: 2%;
    margin-bottom: 2%;
    p {
      text-align: center;
    }
  }
  &__info {
    // margin-left: 20%;
    // margin-right: 20%;
    font-weight: bold;
    color: var(--primario);
    padding-top: 2%;
    i {
      color: var(--cuarto);
    }
    h3 {
      color: black;
      margin-bottom: 20px;
    }
  }
  &__contenedorInfo {
    border-top: solid 1px black;
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
  &__info {
    &--flex {
      display: flex;
      justify-content: space-around;
      text-align: center;
      align-items: center;
    }
    &--column {
      display: flex;
      flex-direction: column;

      margin: 20%;
      img {
        width: 100px;
        height: auto;
      }
    }
  }
}
@media (max-width: 600px) {
  .programas__info {
    &--flex {
      flex-direction: column;
    }
    &--column {
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
